import { ApolloClient, createHttpLink, from, InMemoryCache, makeVar } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { LocalStorageWrapper, persistCache, PersistentStorage } from 'apollo3-cache-persist';
import { PersistedData } from 'apollo3-cache-persist/lib/types';

// CachePersistor, LocalStorageWrapper
// https://github.com/apollographql/apollo-cache-persist/blob/master/examples/web/src/index.tsx

//import { setContext } from '@apollo/client/link/context';
//import jwtManager from '../services/jwtManager';
//import { refreshAccessToken } from './AuthProvider';

/*
const authLink = setContext((_, { headers }) => {
    const { getToken } = jwtManager();
    const access_token = getToken();
    return {
        headers: {
            ...headers,
            authorization: access_token ? `Bearer ${access_token}` : ""
        }
    };
});
*/

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            switch (extensions && extensions.code) {
                case 'UNAUTHENTICATED':
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                    headers: {
                        ...oldHeaders,
//                        authorization: refreshAccessToken(),
                    },
                    });
                    return forward(operation);
                default:
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            }
        })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
});




// --- dark Mode ----
// ref : https://defineall.tistory.com/903

export const darkMode = makeVar(false)


export type UserQuery = {
  userData: {
    id: string;
    age: string;
    details: string;
    access_date_utc: string;
  };
};

const cache = new InMemoryCache({
  typePolicies :{
    Query: {
      fields : {
        darkMode :{
          read() {
            return darkMode()
          }
       }
     }
    }
  }
})


//persist store persisted the cache 

persistCache({
  cache:cache,
  storage: new LocalStorageWrapper(window.localStorage)
}).then((result) => {
  //console.log('storage is persisted')
  //console.log('result from persisted store ->>>> ' , result)
}).catch(err => console.log(err))


const httpLink = createHttpLink({
    uri: "https://liflo.io/graphql",
//    credentials: 'include'
});
  
const authLink = setContext((_ , {headers}) => {
    const token = localStorage.getItem('userToken');
    return {
      headers: {
        ...headers,
        authorization : token ? `Bearer ${token}` : ""
      }
    }
})

const createApolloClient = new ApolloClient({
    cache: cache, //new InMemoryCache(),
    link: from([errorLink, 
        authLink, 
        httpLink]),

});

export default createApolloClient;
/**
 * {
    typePolicies :{
       User: {  //typename from the backend
         fields : {  //fields we want to include
           loginId: { 
            //field we included or we can say that local field not coming from the server
             
            read(_ , {variables}){
              return localStorage.getItem('userId')
           }
         }      
        }
     },
     Task: {  //we can manupulate data before calling the query
        fields: { //here i converted the title property
          title:{  //of Task typename to UpperCase 
            read(title) {
              return title.toUpperCase()
            }
          }
        }
     },
     Query: {
       fields : {
         darkMode :{
           read() {
             return darkMode()
           }
         }
       }
     }
  }
}
 */