import { gql, useMutation } from "@apollo/client";
import { Box, Button, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { userDatas } from "../../../hoc/cache";
import { generateHistoryGQL } from "../../../hoc/gql";


function GenerateHistory(props:any) {

  const [generateHistory, { loading, error, data }] = useMutation(generateHistoryGQL, 
    {      
      onCompleted: (data) => {
        userDatas({...userDatas(), popLayer: 'slide', lfid:data.generateHistory.id, lidx : 0});
        //const url = 'https://fd.liflo.io/slide/'+data.generateHistory.id;
        //window.open(url, '_blank', "height=800,width=550");    
      }
    },
  );

  useEffect(()=>{
  },[]);

  if (loading) return <p>generate History ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  //console.log(data);




  function openFlow() {
    //const url = 'https://liflo.io/link/'+cardLink().flowid+'/'+cardLink().cardid;

    const dataJson = {
       cases:[],
       actions:[["",{type:1,action:[],rules:[]},6,""]], // cat:6(form) - type:1(assessment result)
       content:props.outs.content, 
    }

    //{\"cases\":[{\"id\":\"Connect_1\",\"title\":\"다음\",\"flow\":\"63018ded033fa30319e2d9af\",\"card\":\"63107c0d0d96270310ff8489\",\"img\":\"\",\"pt\":1,\"wt\":1}],\"actions\":[[\"\",{\"type\":3,\"action\":[\"\",\"\"],\"rules\":\"\"},1,\"\"]]}
    //json.flowcardid = cardLink().flowcardid;
    const jsonString = JSON.stringify(dataJson); //, null, "\t");
    //console.log("history data:"+jsonString);


    generateHistory({
      variables: { 
        "id": '', 
        "input": {
                            //"title": "",
                            //"category": "assess",
          "owner": localStorage.getItem('exID'),
          "linkto": "", // existing history flow id
          "data": jsonString
        }
      }
    });
  }

  return(
    <Button variant="contained" color="success" onClick={() => openFlow()} >
    결과 카드 보기
    </Button>
);
}

export default function Assessment(props:any) {
    
    const getResult = () => {
      //if( props.data.content.assessment) 
      return <Typography gutterBottom variant="h6" component="div">
        양호
        </Typography>
    }
    if( props.type === 1 ){
      return(
        <Box component="div" sx={{ width: '80%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 0, mx: 2, marginTop:1 }}>
              <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Typography gutterBottom variant="h6" component="div">                    
                    </Typography>
                  </Grid>
                  <Grid item>
                    {props.data.content.examinee.name}
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 0, mx: 2, marginTop:1  }}>
                <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Typography gutterBottom variant="h6" component="div">
                    안내
                    </Typography>
                  </Grid>
                  <Grid item>
                    검사에 참여해주셔서 감사합니다. 검사 결과는 추후 카카오톡이나 문자로 전달해 드리겠습니다.
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
        </Box>)
    }
    else 
    return(
        <Box component="div" sx={{ width: '80%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 0, mx: 2, marginTop:1 }}>
              <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Typography gutterBottom variant="h6" component="div">
                    검사자
                    </Typography>
                  </Grid>
                  <Grid item>
                    {props.data.content.examinee.name}
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 0, mx: 2, marginTop:1  }}>
                <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Typography gutterBottom variant="h6" component="div">
                    {props.data.actions[0][0]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {getResult()}
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                {props.data.content.assessment.map((v:any,i:number)=>(
                  <Fragment>
                    <Grid item xs={5}>
                      <Typography color={(v.output==='총점')? '#000000':'#555555'} key={i} variant="body1">{v.output}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography color={(v.output==='총점')? '#000000':'#555555'} key={i} variant="body1">{v.point} 점</Typography>                  
                    </Grid>
                  </Fragment>                  
                )) }
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ m: 2 }}>
                <Typography gutterBottom variant="body2">
                  keywords
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Chip label="우울" />
                  <Chip label="ADHD" />
                  <Chip color="primary" label="발달" />
                  <Chip label="치료" />
                </Stack>
              </Box>
              {localStorage.getItem('type') !== 'slide' && <Box component="div" sx={{ m: 2 }}>
              
              </Box>}
        </Box>
    )
}

// <GenerateHistory outs={props.data}/>