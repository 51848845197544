import {Fragment, useEffect, useRef, useState} from "react";
import clonedeep from 'lodash.clonedeep'
//import { useSpring, animated, useSprings, config } from '@react-spring/web';
import { useSprings, animated, to as interpolate, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import BaseCard from "../Card/BaseCard";
import { cardView, userDatas } from "../../hoc/cache";

import "./style.css";
import { useLazyQuery } from "@apollo/client";
import { getFlowGQL } from "../../hoc/gql";
import { Button } from "@mui/material";
import useResize from "../../hook/useResize";
import CloseButton from "./CloseButton";
  
// These two are just helpers, they curate spring data, values that are later being interpolated into css
// rot : -10 + Math.random() * 20,
const to = (i: number) => ({
  x: 0,
  y: i * 1,
  scale: 1,
  rot: 0, 
  delay: i * 100,
})
const from = (_i: number) => ({ x: 0, rot: 0, scale: 1, y: 1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(${s})`
//`perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

interface ICard {
  id: string, title: string; subtitle: string; data: string; category: string; 
  linkto: string; img: string;
}

interface FlowProps {
  flow: {
    id?: string;
    cards?: Array<ICard>;
  };
}

const LayerDeckFlow = (props: FlowProps) => {
    const { flow } = props;
    const [prevIndex, setPrevIndex] = useState(0);

    const flowcards = useRef([]);
    const [cards,setCards] = useState<ICard[]>([]);
    const idxList = useRef([]);    

    const [ getFlowGQLHandler, getFlowGQLRequest] = useLazyQuery(getFlowGQL, {
      onCompleted: (data) => {
        if(data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
          flowcards.current = clonedeep(data.getFlow.flow.cards);
          initFlow();
          /*
          data.getFlow.flow.cards.map( (card:any)=>{
            if( card.title === 'User'){
              userDatas({...userDatas(), nickname: card.subtitle});
              console.log(card.data);
            }
          })
          */           
        }
      },
    });
        
    useEffect(()=>{
      //console.log("*ldf*********************flow.id:"+flow.id);
      getFlowGQLHandler({variables: {id: flow.id},});
      
    },[flow.id]);
        
    const initFlow = async () => {
      //console.log("*ldf*********************flow.id:"+flow.id);
        flowcards.current.map( (v:ICard,i) => {
          if( v.category === "switch" || v.category === "link" || v.category === "message" || v.category === "action")
              if( v.subtitle === "시작")
                  cards.push(v);
              else    
                  cards.push(v);
          return true;    
        })

        //await Promise.all(promises);
        cards.map((card, index) => {
            if( card.id !== null )
                idxList[ card.id] = index;
            return true;    
        })
        setPrevIndex( cards.length - 1);
    }

    const { width : cWidth, height: cHeight } = useResize();

    const onSwitch = (cardId: any) => {
        if( cardId !== ''){
          let idx = idxList[cardId];
          //if( idx !== undefined )
          //  handleClick(idx);
        }
    }

    const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
    const [gprops, api] = useSprings(cards.length, i => ({
      ...to(i),
      from: from(i),
    })) // Create a bunch of springs using the helpers above

    // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
    // @ts-ignore | Weird, 
    const bind = useDrag(({ args: [index], active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
      const trigger = vx > 0.2 // If you flick hard enough it should trigger the card to fly out
      if (!active && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      api.start(i => {
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * xDir : active ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = 0 //mx / 100 + (isGone ? xDir * 10 * vx : 0) // How much the card tilts, flicking it harder makes it rotate faster
        const scale = active ? 1.1 : 1 // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
        }
      })
      if (!active && gone.size === cards.length)
        setTimeout(() => {
          gone.clear()
          api.start(i => to(i))
        }, 600)
    })

    const closeLayer = (e:any) => {
      e.preventDefault();
      userDatas({...userDatas(), popLayer: '', lidx: 0});
    }

    const [wrapperSpring, setWrapperSpring] = useSpring(() => ({
      width: `${cWidth}px`,
      height: `${cHeight}px`,
    }));
        
    return (
      <Fragment>
      <animated.div style={wrapperSpring} className="wrapperLayer">
        <CloseButton doClick={closeLayer}/>
        {gprops.map(({ x, y, rot, scale }, i) => (
          <animated.div className="deckLayer" key={i} style={{ x, y }}>
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div
              {...bind(i)}
              key={"card-" + i}
              style={{
                transform: interpolate([rot, scale], trans),
                backgroundImage: `url(${cards[i]})`,
                width: cWidth,
                zIndex: 999,
              }}>
          <BaseCard card={cards[i]} ctype={2} width={cWidth} height={cHeight} onCaseSelect={(idx:any) => onSwitch(idx)}/>
            </animated.div>
          </animated.div>
        ))}
        </animated.div>
      </Fragment>
    )
}

export default LayerDeckFlow;
