
import { useReactiveVar } from "@apollo/client";
import { Alert, Box, Button, Chip, Grid, Stack, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { userDatas } from "../../hoc/cache";
import Login from "./components/Login";
import { isLocal} from "../Exam/containers/GameFullScreen"
import useStateRef from "../../hook/useStateRef";
import { generateUUID, setUserData } from "../UserInfo/header";

const User = (props:any) => {
   
  const [info , setInfo] = useState('');
  const [message , setMessage] = useState('');
  const [agree , setAgree, agreeRef] = useStateRef<any>({'c1':false,'c2':false,'date':0});

  const { width : cWidth, height: cHeight} = useReactiveVar(userDatas);
  const index = localStorage.getItem('index') as string; 
  
  const cases = useRef<any>([]); // id: 'Connect_1', title: '다음'
  const incases = useRef<any>([]); // id: 'Connect_1', title: '다음'
  const bcases = useRef<any>([]); // id: 'Connect_1', title: '다음'


  useEffect( ()=>{
    //console.log('User In--------------------')
    userDatas().reSize = false; // when v keyboard issue 
    setAgree( userDatas().data.agree);

    getTaskData();

    return(()=>{
      //console.log('User Out--------------------')
      //setUserData();
      //userDatas({...userDatas(), data: {agree:agreeRef.current}});
      //setUserData();
      })
  },[])

  useEffect( ()=>{
    console.log('update data  ++++++++++++++++++:'+userDatas().update);
  },[userDatas().update]);

  const doNext = (idx:number) => {
    props.nextCard(idx);
  }

  let i=0,j=0,k=0;
  //console.log(props.data);
  props.data.cases.map( (v:any,idx:number) =>{
    const sid = -parseInt(v.id.substring(8));
    
    // parse title
    let cmds = [...v.title.matchAll(/\[([a-z])+(\d+);([^\]]+)\]+/g)];
    if( cmds.length === 0 )
      cases.current[i++] = { id:sid, title:v.title}
    else{
      if( cmds[0][1] === 'c' )
        incases.current[j++] = { id:sid, code:cmds[0][1]+cmds[0][2], title:cmds[0][3]}  
      else if( cmds[0][1] === 'b' )
        bcases.current[k++] = { id:sid, code:cmds[0][1]+cmds[0][2], title:cmds[0][3]}  
    }
  })

  const bPrev = cases.current.length > 1;


  const getTaskData = async() => {
    let code = '10000005'; // type id or userDatas().uid
		let url = `https://fd.liflo.io/data/u/t`+code+`.json`;
    //console.log('fetch user url:'+url);
		const response = await fetch(url);
    const udata = await response.json();
    setMessage(udata.message);
    //await getUserData();
  }

/*
  const getUserData = async() => {
    if( userDatas().uid === '') return false;
    //const url2 = 'https://m5.liflo.io/userdata/'+id;
    let apiServer = 'https://m6.liflo.io'; // 
    if( isLocal() ) apiServer = 'http://localhost:8787'; // 'https://m5.liflo.io'

    const url = apiServer + '/userdata/'+userDatas().uid;
    
    let response = await fetch(url);
    let data = await response.json();
    //console.log('get stdinfo:'+data.stdInfo);
    const udata = await JSON.parse(data.userData);
    //console.log('getUserData:'+udata);
    if( data.userData !== '' ){
      userDatas().data.agree = udata.agree;
      setAgree(udata.agree);   
      if( udata.user === undefined )
        getRegisterdUserData();
      return true;
    }
    else       
      return false;      
  }
*/
/*
    const getRegisterdUserData = async() => {
      //console.log('userData:'+userDatas().data.user);
      if( userDatas().data.user !== undefined ) return;
      let id = userDatas().index;
      const url = 'https://fd.liflo.io/data/jinju/'+id+'.json';
    
      let response = await fetch(url);
      let data = await response.json();
      userDatas().data.user = data.user;
      await setUserData();
  
      //userDatas({...userDatas(), data: {user:data.user}});
      //console.log('userData2:'+userDatas().data.user);
    }   

  const setUserData = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userDatas().data)
    };
    console.log('write userData:'+requestOptions.body)
    let apiServer = 'https://m6.liflo.io'; // 
    if( isLocal() ) apiServer = 'http://localhost:8787'; // 'https://m5.liflo.io'
    await fetch(apiServer+'/put/'+userDatas().uid, requestOptions)
        .then(response => response.json())
        .then(data => setInfo('User 등록'));
  }
*/
  const handleRadioChange = (e:any) =>{
    var d = new Date().getTime();//Timestamp
    setAgree({...agree, [e.target.id]:e.target.checked, ['date']:d});
    userDatas().data.agree = agreeRef.current;

    //await userDatas({...userDatas(), data: {agree:agree}});
    //await setUserData();

  }


  const getAgreement = () => {
    
    if( userDatas().data.agree !== undefined && incases.current.length > 0 ){
      return incases.current.map( (v:any,i:any)=>
        <Fragment key={i}>
          <div className="col-span-1" >
        <input type="checkbox" id={v.code} 
        onChange={handleRadioChange} checked={agree[v.code]}
        className="form-check-input h-4 w-4 mr-2 border border-gray-500 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-0 transition duration-200"/></div>
        <div className="col-span-11" onClick={()=>doNext(v.id)}>{v.title} [내용]</div>
        </Fragment>
      )
    }
    else return <Fragment />
  }

  const isAgree = ()=>{
    if( agree === undefined ) return true;
    for( const v of incases.current){
      if( agree[v.code] === false ) return true;
    }
    return false;
  }

  const getButton = () => {
    if( bcases.current.length > 0 ){
      return bcases.current.map( (v:any,i:any)=>
      <Button key={i} id={v.code} color="info" variant="contained" onClick={()=>doNext(v.id)}>{v.title}</Button>
      )
    }
    else return <Fragment />
  }

  const openUserStackCard = (e:any) => {
    e.preventDefault()
    userDatas({...userDatas(), fid: '63d1ea544254b2031cc441e8', popLayer: 'stack'});
  }
  const openUserDeckCard = (e:any) => {
    e.preventDefault()
    userDatas({...userDatas(), fid: '63d1ea544254b2031cc441e8', popLayer:'deck'});
  }
  const openUserSlideCard = (e:any) => {
    e.preventDefault()
    userDatas({...userDatas(), fid: '63d1ea544254b2031cc441e8', popLayer:'slide'});
  }
  
  const openUserCard = (e:any) => {
    e.preventDefault()
    userDatas({...userDatas(), fid: '63d1ea544254b2031cc441e8', popLayer:'stack'});
    
    //const url = 'https://fd.liflo.io/slide/'+localStorage.getItem('exID');
    //window.open(url, '_blank', "height=800,width=550");
  }
  
  //const { width : cWidth, height: cHeight } = useReactiveVar(userDatas);

  const onClick = (e:any) =>{
      userDatas({...userDatas(), popLayer: 'slide'});
  }

  const doExecute = async (e:any) =>{
    if( props.data.actions[0][1].action[2] === undefined ) return;
    //let da = userDatas().data;
    let exID = userDatas().data.user.code+':'+userDatas().uid+':'+generateUUID();
    localStorage.setItem('exID',exID); 

    //userDatas({...userDatas(), data: {agree:agreeRef.current}});
    userDatas().data.agree = agreeRef.current;
    await setUserData();

    const url = '/gen/'+props.data.actions[0][1].action[2];
    window.open(url, '_self', "");    
  }
  let data = userDatas().data;
  return (
    <Box component="div" sx={{ position:"absolute", top: '0', left:'0',display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height:cHeight+'px', width: '100%', bgcolor: 'background.paper'}}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{paddingTop:'13%'}}>
        <Grid item xs={2}></Grid><Grid item xs={10}>
          { info !== '' && <Alert severity="info" >{info}</Alert>}
        </Grid>
        { userDatas().data.user !== undefined && <Fragment> 
          <Grid item xs={1}/><Grid item xs={10}>
            <Typography gutterBottom variant="h6" component="div">
            {userDatas().nickname}의 학부모님 안녕하세요? 
            </Typography>
          </Grid>
          <Grid item xs={1}/>


          <Grid item xs={1}/><Grid item xs={10}>
            <div className={'row hr-11'} style={{whiteSpace: 'pre-line'}}>{message}</div>
          </Grid>
          <Grid item xs={1}/>


          <Grid item xs={1}/><Grid item xs={10}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">아래의 항목에 동의하면 검사를 시작할 수 있습니다.</div>
            {getAgreement()}
            </div>
          </Grid>
          <Grid item xs={1}/>

          <Grid item xs={2}/><Grid item xs={5}>
            {getButton()}
          </Grid>
          <Grid item xs={4}>
            <Button color="success" disabled={isAgree()} variant="contained" onClick={doExecute}>검사 시작</Button>
          </Grid>
          <Grid item xs={1}/>

          <Grid item xs={2}/><Grid item xs={8}>
            <Typography gutterBottom variant="body2">
              keywords
            </Typography>
            <Stack direction="row" spacing={1}>
              <Chip color="primary" label="운동" />
              <Chip color="primary" label="성장" />
              <Chip color="primary" label="발달" />
              <Chip color="primary" label="인지" />
            </Stack>
          </Grid>
          <Grid item xs={2}/>

          </Fragment>
        }
        { index === '' && <>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>잘못된 경로로 서비스에 들어와서 서비스를 사용할 수 없습니다. 제공된 웹주소로 다시 접속 바랍니다.</Grid>
              <Grid item xs={2}></Grid></>
        }

        <Grid item xs={2}></Grid>
        <Grid item xs={8}><Login/></Grid>
        <Grid item xs={2}></Grid>
        
      </Grid>
      {cases.current.length > 0 &&<Box component="div" sx={{ width:'100%', paddingBottom:'5%'}}>
          <Grid container spacing={2}>
            <Grid item xs={1}/>
              <Grid item xs={4}>
                {bPrev && <Button variant="contained" onClick={()=>doNext(cases.current[1].id)} sx={{width:'100%',zIndex:100}}>{cases.current[1].title}</Button>}
              </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
              <Grid item xs={4}>
                <Button variant="contained" onClick={()=>doNext(cases.current[0].id)} sx={{width:'100%',zIndex:100}}>{cases.current[0].title}</Button>
              </Grid>
            <Grid item xs={1}/>
          </Grid>
        </Box>}
    </Box>
  )
}

export default User;


/*
export default function User(props:any) {
  return <Login/>
}
*/
/**
 * 
           <Grid item xs={2}></Grid><Grid item xs={3}>
            <Button color="success" variant="contained" onClick={openUserStackCard} >History-Stack</Button>
          </Grid>
          <Grid item xs={3}>
            <Button color="success" variant="contained" onClick={openUserDeckCard} >History-Deck</Button>
          </Grid>
          <Grid item xs={3}>
            <Button color="success" variant="contained" onClick={openUserSlideCard} >History-Slide</Button>
          </Grid><Grid item xs={1}></Grid>
          <Grid item xs={2}></Grid><Grid item xs={10}>
            <Button color="success" variant="contained" onClick={()=>props.nextCard(0)} >4개월 검사</Button>
          </Grid>
      {darkMode === true ? (<Button color="primary" onClick={changeDarkMode}>Disable DarkMode</Button>):(<Button color="error" onClick={changeDarkMode}>Enable DarkMode</Button>)}
 * {signuploginData && <Alert severity="success">{signuploginData?.login?.msg}</Alert>}
                {props.data.assessment.map((v:any,i:number)=>(
                  <Typography color="text.secondary" key={i} variant="body1">{v.output}:{v.point}</Typography>
                )) }
 */