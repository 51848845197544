import { makeVar, useReactiveVar } from '@apollo/client'


// --- card link ---
interface connectorMQTTType {
    uid: string,
    fid: string,
    cid: string,
    client: any;
    client2: any;
}

export const connectorMQTT = makeVar<connectorMQTTType>({ uid:'', fid:'',cid:'',client: null ,client2: null });


// --- flow process variables ---
interface outputType {
  output: string;
  pt: number;
  wt: number;
}

interface historyType {
    cardid: string;
    select: string;
    outputs: Array<outputType>;
    pt: number;
    wt: number;
}

interface cardLinkType {
    flowid: string;
    cardid: string;
    editcardid: string;
    flowcardid: string;
    goCards: string[];

    outputs: [];
    selected: [];

//    history: Array<historyType>;
    version: string;
    dev: boolean;
}

export const cardLink = makeVar<cardLinkType>({ flowid: "", cardid: "", editcardid: "", flowcardid: "", goCards:[], outputs:[], selected:[], version: "15.22", dev: false });
//outputs:[], selected:[], history: [], version: "15.22", dev: false });

// --- card view ---

interface cardViewType {
    mobile: boolean;
    width: number;
    height: number;
    src: number; // src 0:liflo, 1: cf - static
}
export const cardView = makeVar<cardViewType>({ mobile: true, width: 0, height: 0, src: 0 }); 


// --- user data ---
interface userDataType {

    // user 
    code: string; // tempo login code
    
    uid: string;  // user card id 
    uname: string; // email id
    nickname: string;
    exid: string;  // instance id for user 
    index: string; // id for service access
    fid: string; // user flow id

    data: any;

    // process
    scut: number; // https://fd.liflo.io/id/# shorotCut index 
    cfid: string; // this fid
    sfid: string; // launcher fid
    progress: number;

    // layer --------
    popLayer: string; // layerview type
    lfid: string; // layerview fid
    lidx: number; // layerview index

    // card view --------------
    width: number;
    height: number;
    ratio: number;
    reSize: boolean;
    update: number;
}

export const userDatas = makeVar<userDataType>({
    code: '', 
    uid:'', uname:'', nickname:'', exid:'', index: '', fid: '', 
    data:{}, 
    scut: 0, cfid: '', sfid: '', progress: -1, 
    popLayer: '', lfid: '', lidx: 0, 
    width:1024, height:768, ratio:64, reSize:true, update:0});

// --- exam result related result data --- to be deprecated

interface resultDataType {
    id: string;
    out: string;
    data: any;
}
export const resultDatas = makeVar<resultDataType>({id:'', out:'',data:{}});

// --- task related result data ---

interface taskDataType {
    history: any; //<historyType>{};
    import: any;
    task:any;
}
export const taskDatas = makeVar<taskDataType>({ history: {}, import:{}, task:{}});


// --- game data ---
interface gameDataType {
    step: number;
    stage: number;
    time: number;
    score: number;
    lives: number;
    dev: boolean;
}
export const gameDatas = makeVar<gameDataType>({step:0, stage:0, time: 0, score:0, lives:3, dev:false});
/*
export function SetStep( step:any) {
    const gameData = useReactiveVar(gameDatas);
    gameDatas({...gameData, step:step});
}

export function SetStage( stage:any) {
    const gameData = useReactiveVar(gameDatas);
    gameDatas({...gameData, stage:stage});
}
*/


// --- eyeTrack ---
interface positionType {
    x:number,
    y:number,
    z:number,
}
interface eyeTrackType {
    position: positionType;
    glabella: positionType;
    face: positionType;
    iris: positionType;
    distance: number;
}
export const eyeTrack = makeVar<eyeTrackType>({ position:{x:0,y:0,z:0},glabella:{x:0,y:0,z:0}, distance:0
    ,face:{x:0,y:0,z:0} ,iris:{x:0,y:0,z:0} });
