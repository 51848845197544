
import "./style.css";

import { lazy, Suspense, useState} from 'react';
import Box from '@mui/material/Box';
import useResize from "../../hook/useResize";

interface ResizableFrameProps {
  src: any;
  title: string;
  path: string;
  cid: string;
  status: number;
  nextCard: any;
  iframeStyles?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Port = ({
  src,
  title,
  path,
  cid,
  status,
  nextCard,
  iframeStyles = {},
  ...rest
}: ResizableFrameProps): JSX.Element => {


  //let cWidth = cardView().width;
  //let cHeight = cardView().height;
  const { width : cWidth, height: cHeight } = useResize();


  const Base = lazy(() => import('../Port/Base'));
  const Import = lazy(() => import('../Port/Import'));
  return (
    <Box component="div" height={(cHeight) + 'px'} width={(cWidth) + 'px'}>
      <Suspense fallback={null}>              
        { src.actions[0][1].action[0] === "../Port/Base" && 
          <Base title={title} nextCard={(cIdx:number)=>nextCard(cIdx)} />}
        { src.actions[0][1].action[0] === "../Port/Import" && 
          <Import title={title} data={src} nextCard={(cIdx:number)=>nextCard(cIdx)} />}
      </Suspense>
    </Box>
  )

};

// maxWidth={viewportWidthMap[viewportWidth]}
export default Port;




