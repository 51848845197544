import { Box, Chip, Divider, Grid, Stack, Typography } from "@mui/material";

export default function Profile(props:any) {

    return(
        <Box component="div" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h5" component="div">
                    {props.data.name}의 UserCard
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h6" component="div">
                    인지 검사 :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" component="div">
                      양호
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 3, mx: 2 }}>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ m: 2 }}>
                <Typography gutterBottom variant="body2">
                  keywords
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Chip label="우울" />
                  <Chip color="primary" label="ADHD" />
                  <Chip label="발달" />
                  <Chip label="치료" />
                </Stack>
              </Box>
        </Box>
    )
}