import "./style.css";

import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from "@mui/material/ImageListItemBar";


import { CardMedia, Grid } from "@mui/material";
import SelectList from "./SelectList";
import { resultDatas, cardLink, cardView, connectorMQTT, userDatas, taskDatas} from "../../hoc/cache";
import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";
import { Fragment, useEffect, useState } from "react";
import Progress from "../UserInfo/Progress";

const Title = styled.h1`
  text-align: center;
  font-family: "NanumC", "Roboto", sans-serif;
  font-size: xx-large;
  font-weight: bold;
  color: #000;
  margin-bottom: 0px;
  margin-top: 10px;
`;
interface CardProps {
  card: {
    id: string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
  };
  onCaseSelect: any;
}



const SwitchCard = (props: CardProps) => {
  
  const { card, onCaseSelect } = props;
  const { id : id, data: data} = useReactiveVar(resultDatas);
  
  //const { popLayer: popLayer, width : cWidth, height: cHeight } = useReactiveVar(userDatas);
  const popLayer = userDatas().popLayer;
  const cWidth = userDatas().width;
  const cHeight = userDatas().height;

  //const [progress, setProgress] = useState(-1); 

  let progress = -1;
  let mode = 1; // 1 image 

  //useEffect( ()=>{},[card.data]);
  let subtitle = "mbisq" 

  if( card.data === null || card.data === "") return(<Fragment/>);
  else {
    console.log('in SwitchCard ----')
    let content = card.subtitle;

    // ~~~(2/14)~~ -> 2,14,~~~(2)~~ 
    let cmds = [...content.matchAll(/\((\d+)\/(\d+)\)+/g)];
    let sContent = content;
    if( cmds.length !== 0 ){
      cmds.map((cmd,i)=>{
        //console.log(cmd[0]);
        sContent = sContent.replace(cmd[0], '('+cmd[1]+')');
        console.log(sContent)
        progress= (parseInt(cmd[1])*100/parseInt(cmd[2])|0);
      })
    }
    subtitle = sContent;
    //else{setProgress( -1);}
    if(sContent === "Start Card" || sContent === "Select Card") subtitle = "mbisq";
    //setSubtitle(sContent);
  }

  const caseData = JSON.parse(card.data);
  
  caseData.cases.map((item:any) =>{
    if( item.img !== "" ) mode = 2;
    return true;
  })
  let numCases = caseData.cases.length;

  const selectCase = (caseid:string, cardid:string, outputs:[], pt:number, wt:number)  => {
    onCaseSelect(cardid);


    let obj = {cardid:props.card.id, select:caseid, outputs: outputs, pt:pt===undefined?1:pt, wt:wt===undefined?1.0:wt};
    if( caseData.actions[0][1].type !== 6){ // remove Examinee Card
      //cardLink().history.unshift(obj);
      //taskDatas().history[`${props.card.id}`] = obj;
      taskDatas().history[props.card.id] = obj;
    }

    const d = new Date();
    let now = d.getTime();

    let exID = localStorage.getItem('exID');
    if( exID !== null ){
      // PHQ, GAD, 
      let sendData = JSON.stringify({return:true, message:"cnt answers", data:{ uid: exID, game:101, aid: card.subtitle, cid: card.id, sid: caseid, update: now}});
      localStorage.setItem(`${101}`,sendData);
      
      let topic = '/mbisq/data';
      if( card.subtitle.indexOf('ARS_T') !== -1)
        topic = '/mbisq/tdata';
      connectorMQTT().client.publish(
        topic, 
        sendData, 
        { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");}
      );  

      //if(connectorMQTT().client2 !== null) 
      connectorMQTT().client2.publish(
        topic, 
        sendData, 
        { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");}
      );  
    }
    if( caseData.actions[0][0].indexOf('[End of Answers]') !== -1 ){
      localStorage.setItem(`${101}`,'1');
    }
    //alert(cardLink().history.length+":"+cardLink().history[cardLink().history.length-1].cardid+":"+cardLink().history[cardLink().history.length-1].select);
  }

  const sHeight = numCases*45+(numCases-1)*8;
  const tHeight = cHeight-sHeight-120; //cHeight * (cHeight< 700?40:67)/100 |0;



  const imgWidth = ((cWidth- (numCases > 2 ? 60: 5)) / 2)|0;

  let viewmode = 1;
  if( caseData.actions !== undefined && caseData.actions[0].length === 4)
    viewmode = caseData.actions[0][2];  

  let switchDir = 0; //"column";
  let switchAlign = "center";  

  let vert = false;
  if( viewmode === 1){// normal
  }
  else if( viewmode === 2){// image
    switchDir = 1; //"row";
    switchAlign = "flex-end";
  }
  else if( viewmode === 3){// select
    switchDir = 1; //"row";
    switchAlign = "space-evenly";
  }
  else if( viewmode === 4 && cWidth > cHeight ){// select
    switchDir = 0; //"row";
    switchAlign = "space-evenly";
    vert = true;
  }
  //console.log('card id compare : '+card.id +"="+ cardLink().cardid);
  const srv = cardView().src === 1 ? "" : "https://liflo.io";
  let bgimage = (caseData.actions[0][3] === ""|| caseData.actions[0][3] === undefined)? srv+"/images/white.png" : `${caseData.actions[0][3].replace(/fileServer/i, srv)}`;

  function getTitle(){
    
    // title format
    // title : [#;title1] title2 
    // title2 : ~~{1;keyword}~~

    let content = card.title;

    // keyword replace {k:user} -> user name : userDatas().nickname
    content = content.replace('{k:user}', userDatas().nickname);
    console.log('content:'+content);
    //let found = title.match(/\[;([^}]+)\]+/g);
    // / \[ (\s*|\d+) ; ([^}]+) \]+ /g
    //let found = title.match(/\{(\s*|\d+);([^}]+)\}+/g);
    let cmds = [...content.matchAll(/\[(\s*|\d+);([^\]]+)\]+/g)];
    /**
    cmd = '' & 0 : title 
    cmd = 1 : userData 
    cmd = 2 : keyword
    cmd = 11 : vscroll
     */
    let title = "";
    //let cnts: string[] = [];
    //let idxCnt = 0;
    let scClass = 'row hr-11';
    let sContent = content;
    let sMsg = '';
    if( cmds.length !== 0 ){
    
      cmds.map((cmd,i)=>{
        //console.log(cmd[0]);
        if( cmd[1] === '1' ){
          const msg = userDatas().data;
          //cnts[idxCnt++] = msg[cmd[2]];
          if( Object.keys(msg).length !== 0 )
            sContent = sContent.replace(cmd[0], msg[cmd[2]]);
          else   
            sContent = sContent.replace(cmd[0], "로그인을 하시면 서비스를 사용할 수 있습니다.");
          //return <div className="overflow-y-auto row-s hr-21" style={{whiteSpace: 'pre-line'}}>{msg[cmd[2]]}</div>;  
        }
        else if( cmd[1] === '' && cmd.index === 0){
          title = cmd[2];
          sContent = sContent.replace(cmd[0], "");
          //return <div className="flex justify-between items-center text-2xl text-blue-600">{cmd[2]}</div>
        }
        else if( cmd[1] === '2'){
          //cnts[idxCnt++] = cmd[2];
          let msg = cmd[2];
          if( cmd[2] === 'Regist'){
            
            if( userDatas().uid !== '') msg = userDatas().nickname + "님을 위한 검사를 하려면 '내 카드' 버튼을 눌러주세요."; // userDatas().nickname + "님, 
            else msg = '검사 시작을 위해서 사용자 등록을 해주세요.';
          }
          sContent = sContent.replace(cmd[0], '');
          sMsg = msg;
          //return <div className="flex justify-between items-center text-2xl text-blue-700">{cmd[2]}</div>
        }
        else if( cmd[1] === '11'){
          scClass = 'overflow-y-auto row-y hr-11';
          title= cmd[2];
          sContent = sContent.replace(cmd[0], "");
        }
        else
          sContent = sContent.replace(cmd[0], cmd[2]);
      })
    }
    else{
      return  <div className="overflow-y-auto row-t hr-21" style={{whiteSpace: 'pre-line', height:tHeight}}>{content}</div>;
    } 

    return(
      <div style={{display:'flex',  flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <div className="flex justify-between items-center text-2xl text-blue-600">{title}</div>
          <div className="grid grid-cols-1 divide-y" style={{height:tHeight}}>
          <div className={scClass} style={{whiteSpace: 'pre-line'}}>{sContent}</div>
          {sMsg !== '' && <div className={scClass} style={{whiteSpace: 'pre-line'}}>{sMsg}</div>}
          </div>
      </div>
    )
/*
          {cnts.map((v,i)=><div className={scClass} style={{whiteSpace: 'pre-line'}}>{v}</div>)}
    { // [;주의력 설문지(중학생용)]\n다음의 질문을 잘 읽고 평소 나한테 해당하는 곳에 체크해주세요.
      let start = found[0].length;
      // [1;~~~]~~~ 
      let deliSC = title.indexOf(';');
      let title0 = title.substring(1,deliSC);
      let title1 = title.substring(deliSC+1,start-1);
      let title2 = title.substring(start);
      //console.log( 'title index:'+title0+'|'+title1+'|'+title2+'|')
      
      let scClass = 'row hr-11';
      if( title0 === '11'){
        scClass = 'overflow-y-auto row-s hr-11';
      }

      if( title1.indexOf('Temp_') !== -1){
        return <Forms template={title1} title={title2}/>
      }
      else if(title1.indexOf('userData') !== -1){   
        //const msg = userDatas().data;
        //console.log(msg[title2]);
        //return <div className="overflow-y-auto row-s hr-21" style={{whiteSpace: 'pre-line'}}>{msg[title2]}</div>;
      }
      else{
        //console.log(title1+'/'+title2);
        return <div style={{display:'flex',  flexDirection: 'column', alignItems: 'center', width: '100%'}}>
        <div className="flex justify-between items-center text-2xl text-blue-600">{title1}</div>
        <div className={scClass} style={{whiteSpace: 'pre-line', height:tHeight}}>{title2}</div>
        </div>
  
      }
    }
  */
  }

  if( props.card.id === 'g001'){
    console.log('stop')
  }
  if( cardLink().cardid === '')
    cardLink().cardid = card.id;

  //let lOffset = (window.innerWidth - cWidth)/2|0; {progress !== -1&&<Progress loffset={lOffset} width={cWidth - 200} completed={progress} />}
  console.log('render --------------------------')
  const bCase = ((popLayer === 'slide') || (card.id === cardLink().cardid || localStorage.getItem('type') === 'card'));  
  return (
    <CardContent sx={viewmode === 1 ? {position: 'absolute',
        zIndex: 2,
        width: cWidth,
        height: cHeight,
        top: 0,
        padding: '0px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        }: {position: 'absolute',
        zIndex: 2,
        width: cWidth,
        height: cHeight-5,
        top: 0,
        padding: '0px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: vert?'#fff':'#fff0',
        alignItems: (viewmode === 2)?'flex-end':'flex-start',
      }}>
      
      <CardContent sx={{bottom: '0px',}} >
        <div className="row hr-1" >
          <div>{subtitle}</div>{progress !== -1&&<Progress width={'60%'} completed={progress} />}
        </div>
      </CardContent>

      <CardContent id="cardtitle" sx={{position:'absolute', top:60, height:(viewmode === 4)?(cHeight*2/5|0)+'px':'auto', padding: '0px', paddingBottom:'10px'}} >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} width={cWidth}>
          <Grid item xs={1}/>
          <Grid item xs={10}>
          <div className={vert?"row hr-32":"row hr-22"} >
            {getTitle()}
          </div>
          </Grid>
          <Grid item xs={1}/>
        </Grid>
      </CardContent>

      {bCase && 
        <CardContent sx={{position:'absolute', bottom:0, padding: 0, width: cWidth}} >
          { mode === 2 ? (
            <ImageList cols={ numCases > 2 ? 2 : 1} sx={{alignItems:'center', justifyItems: 'center'}}>
            {caseData.cases.map((item:any) => 
                <ImageListItem key={item.title} sx={{width:(numCases > 2) ?'95%':'60%'}}
                onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}
              >
                  { item.img !== "" && (
                         <div><img
                         src={`${srv+"/images/" + item.img}?w=400&h=400&fit=crop&auto=format`}
                         srcSet={`${srv+"/images/" + item.img}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
                         alt={item.title}
                         style={{ width: imgWidth+'px'}}
                         loading="lazy" /><ImageListItemBar position="below" title={""} /></div>
                  )}
                  </ImageListItem>
            )}
          </ImageList>
          ) : (
            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} width={cWidth}>
              <Grid item xs={1}/>
            {vert&&
              <Grid item xs={5}>
                <div style={{display:'flex',  flexDirection: 'column', alignItems: 'center'}}>
                  <CardMedia component="img" image={bgimage} alt="liflo" sx={{ height: cHeight*0.8, width: imgWidth}}/>
                </div>
              </Grid>
            }
              <Grid item xs={vert?5:10} sx={{display:'flex',  flexDirection: 'column', justifyContent: 'center'}}>           
                <SelectList cardid={card.id} cases={caseData.cases} selectCase={selectCase} switchDir={switchDir} switchAlign={switchAlign} vert={vert}/>
              </Grid>
              <Grid item xs={1}/>
            </Grid>
          )}
        </CardContent>}
    </CardContent>
  );
};
// variant="contained"
export default SwitchCard;
/**
 * 
 <Stack direction={switchDir===0?'column':'row'} justifyContent={switchAlign}  spacing={{ xs: 4, sm: 2, md: 1 }}>
 {caseData.cases.map((item:any) => {
              function getIcon(){ 
                // SentimentDissatisfiedOutlined / SentimentNeutralOutlined
                return <SentimentSatisfiedAlt viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit',
              marginRight: '30px', marginTop: '-24px'}}/>;}
              return  <Button key={item.title} variant="outlined" startIcon={getIcon()} color="success"  onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)} >
              {item.title}
              </Button>;
            }
            )
}
            </Stack>

      <div className="row row-start meta-section">
       <div>{card.data}</div>
      </div>

      *     <Card sx={{ maxWidth: "300", minHeight: "650", borderRadius: 3 }} variant="outlined">
 *       <svg
        className="line-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 172.03 0.8"
      >
        <path
          d="M341.49,225h172"
          transform="translate(-341.49 -224.5)"
          fill="none"
          stroke="#000000"
          strokeMiterlimit="10"
        />
      </svg>

 */