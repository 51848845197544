import { ReactNode } from 'react';
import Box from '@mui/material/Box';

import User from '../Form/User';
import Exam from './Exam';
import Form from './Form';
import Port from './Port';
import IFrame from './IFrame';

//import Container from 'components/Container';
interface Props {
    children: ReactNode;
    // All other props
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
}
  
const Container = ({ children,  ...rest }: Props): JSX.Element => (
    <Box component="div"
      maxWidth={{ sm: 720, md: 1236 }}
      width={1}
      height={'100%'}
      margin={'0 auto'}
      paddingX={0}
      paddingY={{ xs: 1, sm: 1, md: 1 }}
      {...rest}
    >
      {children}
    </Box>
);



interface ActionFrameProps {
  src: any;
  title: string;
  path: string;
  cid: string;
  status:number;
  nextCard:any;
}

const Action = ({
  src,
  title,
  path,
  cid,
  status,
  nextCard,
  ...rest
}: ActionFrameProps): JSX.Element => {

  return (
    <Container maxWidth={1500} {...rest}>
      { src.actions[0][1].type === 2 && <IFrame cid={cid} src={src.actions[0][1].action[0]} audio={(src.actions[0][1].action[1]===undefined)?"":src.actions[0][1].action[1]} title={""} path={""}/>}
      { src.actions[0][1].type === 8 && <User nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
      { src.actions[0][1].type === 9 && <Exam cid={cid} status={status} src={src.actions[0][1].action[0]} title={src.actions[0][0]} path={""} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
      { src.actions[0][1].type === 10 && <Form cid={cid} status={status} src={src.actions[0][1].action[0]} title={src.actions[0][0]} data={src} path={""} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
      { src.actions[0][1].type === 11 && <Port cid={cid} status={status} src={src} title={src.actions[0][0]} path={""} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
    </Container>
  );
};

export default Action;