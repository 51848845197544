import styled from 'styled-components';

interface Props {
  settings?: boolean;
  primary?: boolean;
}

export const Button = styled.button<Pick<Props, 'settings' | 'primary'>>`
  padding: ${(props) => (props.settings ? '8px' : '12px')};
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.primary ? '#fff' : 'inherit')};

  border: 5px;
  border-color: '#aaa';
  border-radius: 8px;

  margin: 4px;
  cursor: pointer;
  background: ${(props) =>
    props.primary ? '#52a2aa' : props.settings ? '#fff' : '#ddd'};
`;


export const ExitButton = styled.button<{img:any,imgD:any, left:any}>`
  position: absolute;
  z-index: 150;
  width: 100px;
  height: 100px;
  left: ${({left}) => left};
  top: 5%;
  padding-top: 0px;

  background: url(${({img}) => img});
  background-repeat: no-repeat;
  background-size: 100px 100px;
  border: none;

  font-family: "NanumPen", "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: rgb(25, 25, 25);

  :active {
    background: url(${({imgD}) => imgD});
    background-repeat: no-repeat;
    background-size: 100px 100px;
    border: none;
  }
`;

export const StartButton = styled.button<{img:any, imgD:any, left:any}>`
  position: absolute;
  z-index: 150;
  width: 15%;
  height: 20%;
  left: ${({left}) => left};
  top: 75%;
  padding-bottom: 2%;

  background: url(${({img}) => img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;

  font-family: "NanumPen", "Roboto", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: rgb(25, 25, 25);

  :active {
    background: url(${({imgD}) => imgD});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
  }
`;

export const ButtonTitle = styled.h1`
  text-align: center;
  font-family: "NanumPen", "Roboto", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30%;
  margin-top: 0px;
`;