import { useState, useEffect, memo } from 'react';

import {Button} from '../components/Button';
import {Window as ModalWindow, Title as ModalTitle} from '../components/Modal';
import styled from 'styled-components';
import { cardView } from '../../../hoc/cache';

const Container = styled.div`
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0px;
  background: #aaaaaa;
  width: 300px;
  height: 50%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 15px;
`;

export const fullScreen = async () => {

  const doc = document.documentElement as any;

  
  if (typeof doc.requestFullscreen === "function") {
    console.log(typeof doc.requestFullscreen);
    doc.requestFullscreen();
  }
  else if (typeof doc.webkitRequestFullscreen === "function") {
    console.log(typeof doc.webkitRequestFullscreen);
    doc.webkitRequestFullscreen();        
  }
  else if (typeof doc.mozRequestFullScreen === "function") {
    console.log(typeof doc.mozRequestFullScreen);
    doc.mozRequestFullScreen();        
  }
  else if (typeof doc.msRequestFullscreen === "function") {
    console.log(typeof doc.msRequestFullscreen);
    doc.msRequestFullscreen();        
  }

  // eslint-disable-next-line
  /*
  if( cardView().mobile ) screen.orientation.lock('landscape');
  setShowModal(false);
  reset();
  */
/*
  const t = await setTimeout(() => {
    // eslint-disable-next-line
    screen.orientation.lock('landscape');
    setShowModal(false);
    clearTimeout(t);
    reset();
  }, 1000); 
*/ 
}

//@ts-ignore
export const GameFullScreen = memo( ({reset}:any )  => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      setShowModal(true);
    }, []);
    
    return (
      !ios() && <ModalWindow show={showModal} bshow={false}>
        <Container>
        <ModalTitle>
        원활한 검사의 진행을 위해서 전체 화면이 되어야합니다.
        </ModalTitle>
        <br/>
        <Button onTouchEnd={()=>{fullScreen()}} onClick={()=>{fullScreen()}}>
          <i className='fa fa-fw fa-sign-out' /> 전체 화면으로 바꾸기
        </Button>
      </Container>
      </ModalWindow>
    );
  }
);

export const isFullScreen = () => {
  //if( window.innerHeight > 600 ) return true;
  //else return false;

  if( document.fullscreenElement !== null  && !iphone()) return true;
  else return false;
  
  // eslint-disable-next-line
  let dif = screen.height - window.innerHeight;
  dif = dif*dif;
  // eslint-disable-next-line
  return ( screen.width === window.innerWidth && dif < 4 ); // screen.height === window.innerHeight); //!window.screenTop && !window.screenY);
  
}

export const ios = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  //@ts-ignore
  // eslint-disable-next-line  
  return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
};

export const iphone = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  //@ts-ignore
  // eslint-disable-next-line  
  return /iPhone/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
};

export const isLocal = () => {
  return (window.location.host.indexOf('localhost') !== -1)
}
